import { template as template_0a8a34b38a1d41b1b9c6f0b028b33996 } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_0a8a34b38a1d41b1b9c6f0b028b33996(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
