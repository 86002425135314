import { template as template_ba09cde359fb433baac8a8c0137c5968 } from "@ember/template-compiler";
const FKText = template_ba09cde359fb433baac8a8c0137c5968(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
